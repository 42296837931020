import React, { Fragment, ChangeEvent } from 'react';
import { useState, useEffect } from 'react';

import stationery from 'image/icons/activity/stationery-business-unselected.svg'
import stationeryActive from 'image/icons/activity/stationery-business-selected.svg'
import lawyer from 'image/icons/activity/domiciled-lawyer-unselected.svg'
import lawyerActive from 'image/icons/activity/domiciled-lawyer-selected.svg'
import substitute from 'image/icons/activity/substitute-hearing-selected-unselected.svg'
import substituteActive from 'image/icons/activity/substitute-hearing-selected.svg'
import modalGirl from 'image/icons/activity/modalGirl.svg'
import send from 'image/icons/activity/send.svg'
import strings from 'values/strings';
import classNames from 'classnames';
import ConfirmModal from './modal/ConfirmModal';
import DatePicker from 'react-datepicker';
import { FormFields, DateFormat } from 'values/values';
import { updateRequestData, RequestReducer } from 'store/reducers/RequestReducer';
import { useDispatch, useSelector } from 'react-redux';
import SelectOption from '../../store/types/SelectionOption';
import RequestField, { RequestFieldType } from './RequestField';
import _ from 'lodash';
import ActivitiesService from '../../services/ActivitiesService';
import { Request } from '../../store/types/Request';
import moment from 'moment';
import { setRequestData } from '../../store/reducers/RequestReducer';
import { ActivityModal } from 'store/types/ActivityModal';
import smoothscroll from 'smoothscroll-polyfill';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import { isMobile } from 'react-device-detect';
import LightSignUp from './LightSignUp';
import { createTempUser, IUserReducer } from 'store/reducers/UserReducer';
import UserService from 'services/UserServices';
import { User } from 'store/types/User';
smoothscroll.polyfill();

interface Props {
  type?: string
  currentUser?: User
}

const requestType = [
  {
    unselected: lawyer,
    selected: lawyerActive,
    name: 'DOMICILIARY',
    text: strings.requestActivities.type.domiciliary,
  },
  {
    unselected: stationery,
    selected: stationeryActive,
    name: 'CHANCELLERY',
    text: strings.requestActivities.type.cancellery,
  },
  {
    unselected: substitute,
    selected: substituteActive,
    name: 'SUBSTITUTE',
    text: strings.requestActivities.type.substitute,
  },
]

enum PriceRanges { custom, none }

enum RequestActivitySteps { First = 1, Second, Third, Fourth, Fifth, Sixth }

const RequestActivitiesGuest: React.FC<Props> = ({ type, currentUser }) => {


  const [isLoading, setIsLoading] = useState(false);

  let [requestResponse, , requestRequest] = ActivitiesService.useGuestCreateRequestAPI()
  const [registerResponse, registerError, registerRequest] = UserService.useLightRegister();

  let [step, setStep] = useState<RequestActivitySteps>(RequestActivitySteps.First)
  const [activityType, setActivityType] = useState(undefined);

  let [datetime, setDatetime] = useState<any>({
    date: undefined,
    time: undefined
  })

  let [selectedRange, setSelectedRange] = useState<any>()
  const [customPrice, setCustomPrice] = React.useState<any>(strings.requestActivities.other);

  const [opened, setOpened] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
  let requestData: Request = useSelector((state: RequestReducer) => state.requestReducer.requestData)

  let dispatch = useDispatch()

  const createAccount = () => {
    if (requestResponse !== undefined && requestResponse.id) {
      registerRequest(tempUser!, requestResponse.id);
    }
  };

  const openedType: ActivityModal =
  {
    img: modalGirl,
    title: strings.requestActivities.modal.opened.title,
    copy: strings.requestActivities.modal.opened.copy,
    cta: strings.requestActivities.modal.opened.cta,
    ctaUndo: strings.requestActivities.modal.opened.ctaUndo,
    onConfirm: () => { setOpened(false); createAccount(); setIsLoading(true) },
    onCancel: () => setOpened(false),
  }

  const confirmedType: ActivityModal =
  {
    img: send,
    title: strings.requestActivities.modal.guest.title,
    copy: strings.requestActivities.modal.guest.copy,
    copy2: strings.requestActivities.modal.guest.copy2,
  }

  useEffect(() => {
    dispatch(createTempUser());
    dispatch(setRequestData(new Request()))
    dispatch(updateRequestData(FormFields.type, type))
  }, [])

  useEffect(() => {
    dispatch(updateRequestData(FormFields.money, customPrice))
  }, [customPrice])

  let minPrice = () => {
    let min_chancellery_value = 20
    let min_value = 35
    return requestData.type == 'CHANCELLERY' ? min_chancellery_value : min_value
  }

  useEffect(() => {
    setCustomPrice(minPrice())
    setSelectedRange(PriceRanges.custom)
  }, [activityType])

  useEffect(() => {
    if (!requestResponse) return

    AnalyticsService.screenName(AnalyticsScreens.activityRequestDone)

    setIsLoading(false)
  }, [requestResponse])

  useEffect(() => {
    if (!registerResponse) return

    AnalyticsService.screenName(AnalyticsScreens.activityRequestDone)
    setIsLoading(false)
    setConfirmed(true)
  }, [registerResponse])

  useEffect(() => {
    if (datetime.date && datetime.time) {
      let date = moment(datetime.date).format(DateFormat.server)
      let time = moment(datetime.time).format(DateFormat.time)
      dispatch(updateRequestData(FormFields.datetime, date + 'T' + time))

    } else {
      dispatch(updateRequestData(FormFields.datetime, undefined))
    }
  }, [datetime])

  useEffect(() => {
    sendAnalyticsScreenName(step)

    if (step === 3) {
      document.querySelectorAll<HTMLElement>('.step3').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    } else if (step === 4) {
      document.querySelectorAll<HTMLElement>('.step4').forEach(node => node.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' }))
    } else if (step === 6) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [step])

  let isSelectedRange = (range: any) => {
    return range === selectedRange
  }


  useEffect(() => {
    if (selectedRange === PriceRanges.none) {
      dispatch(updateRequestData(FormFields.money, null))
    }

  }, [selectedRange])


  let sendAnalyticsScreenName = (position: number) => {
    switch (position) {
      case 1: AnalyticsService.screenName(AnalyticsScreens.activityRequest1); break
      case 2: AnalyticsService.screenName(AnalyticsScreens.activityRequest2); break
      case 3: AnalyticsService.screenName(AnalyticsScreens.activityRequest3); break
      case 4: AnalyticsService.screenName(AnalyticsScreens.activityRequest4); break
    }
  }


  const handleIncrement = () => {
    setSelectedRange(PriceRanges.custom)

    if (customPrice < 50) {
      setCustomPrice(customPrice + 5);
    } else {
      setCustomPrice(customPrice + 10);
    }
  };

  const handleDecrement = () => {
    setSelectedRange(PriceRanges.custom)

    if (customPrice > 50) {
      setCustomPrice(customPrice - 10);
    } else if (customPrice > minPrice()) {
      setCustomPrice(customPrice - 5);
    }
  };

  let priceFilter = () => {
    return (
      <div className={'price__filter'}>

        <div className='price__selector'>
          <button
            className={classNames('date', 'button-filter_minus')}
            onClick={handleDecrement}>{'-'}</button>

          <span className={classNames('price__value ', { active: isSelectedRange(PriceRanges.custom) })}>{customPrice}€</span>

          <button
            className={classNames(['date', 'button-filter_plus'])}
            onClick={handleIncrement}>{'+'}</button>
        </div>

        <button
          className={classNames('button-filter date', { active: isSelectedRange(PriceRanges.none) })}
          onClick={() => setSelectedRange(PriceRanges.none)}>{strings.requestActivities.tbd}</button>

      </div>
    )
  }


  // RENDER

  let renderStep1 = () => {
    return (
      <div>
        <h3><span>{strings.requestActivities.step1.title}</span> {strings.requestActivities.step1.title2}</h3>
        <div className={'request__cta__container'}>
          {requestType.map((item: any, index: number) => {
            const isClicked = requestData.type === item.name

            return (
              <div
                key={String(index)}
                className={'cta__container'}
                onClick={() => {
                  dispatch(updateRequestData(FormFields.type, item.name))
                  setActivityType(item.name)
                }} >
                <img
                  className={isClicked ? 'active' : undefined}
                  src={isClicked ? item.selected : item.unselected}
                  alt={item.name} />

                <p className={isClicked ? 'active' : undefined}>{item.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  let renderStep2 = () => {
    return (
      <Fragment>
        {renderStep1()}
        <div className={'request__step__container'}>
          <div className={'request__steps'}>
            <div className={'request__activity'}>
              <h3>{strings.requestActivities.step2.title}<span>{strings.requestActivities.step2.title2}</span>{strings.requestActivities.step2.title3}</h3>

              <RequestField
                type={RequestFieldType.activity}
                value={requestData.activity}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.activity, option.value))} />
              <p>{strings.requestActivities.obligatory}</p>
            </div>

            <div className="request__price__container">
              <h3>{strings.requestActivities.step2.fee}</h3>
              {priceFilter()}
              <p className={'disclaimer'}>{strings.activityPanel.activity.vat_cpa}</p>
            </div>
          </div>

          <form>
            <div className={'form__full'}>
              <h3>{strings.agenda.add.addAnnotation}</h3>
              <textarea
                placeholder={strings.fieldPlaceHolderAnnotation}
                value={requestData.notes}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => dispatch(updateRequestData(FormFields.notes, e.target.value))} />
            </div>
          </form>
        </div>
      </Fragment>
    )
  }

  let renderStep3 = () => {
    return (
      <Fragment>
        {renderStep2()}
        <div className={'request__step__container step3'}>
          <div className={'request__steps'}>

            <Fragment>
              <div className={'request__activity'}>
                <h3><span>{strings.requestActivities.step3.title}</span>{strings.requestActivities.step3.title2}</h3>
                <RequestField
                  type={RequestFieldType.office}
                  value={requestData.office}
                  onSelectedObject={(object) => {
                    dispatch(updateRequestData(FormFields.office, object.value))
                  }} />
                <p>{strings.requestActivities.obligatory}</p>
              </div>
            </Fragment>

            <div className="request__input__container">
              <h3>{strings.requestActivities.step3.matter}</h3>
              <RequestField
                type={RequestFieldType.subject}
                value={requestData.subject}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.subject, option.value))} />
            </div>

            <div className="request__input__container">
              <h3>{strings.requestActivities.step3.section}</h3>
              <RequestField
                type={RequestFieldType.section}
                value={requestData.section}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.section, option.value))} />
            </div>

            <div className="request__input__container">
              <h3>{strings.requestActivities.step3.judge}</h3>
              <RequestField
                type={RequestFieldType.judge}
                value={requestData.judge}
                onChange={(option: SelectOption) => dispatch(updateRequestData(FormFields.judge, option.value))} />
            </div>

          </div>
        </div>
      </Fragment >
    )
  }

  let renderStep4 = () => {
    return (
      <div>
        {renderStep3()}
        <Fragment>
          <div className={'request__step__container step4'}>
            <div className={'request__steps'}>
              <div className={'request__activity'}>
                <h3><span>{strings.requestActivities.step5.title}</span>{strings.requestActivities.step5.title2}</h3>
                <form>
                  <div className={'request__date__container'}>
                    <DatePicker
                      placeholderText={strings.requestActivities.step5.date}
                      popperPlacement={!isMobile ? 'right' : 'auto'}
                      selected={datetime.date ? moment(datetime.date).toDate() : undefined}
                      onChange={(date: Date) => setDatetime({ ...datetime, date: date })}
                      locale="it"
                      dateFormat="eeee d MMMM" />
                    <DatePicker
                      placeholderText={strings.requestActivities.step5.time}
                      popperPlacement={!isMobile ? 'right' : 'auto'}
                      selected={datetime.time ? moment(datetime.time).toDate() : undefined}
                      onChange={(date: Date) => setDatetime({ ...datetime, time: date })}
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      timeIntervals={5}
                      timeCaption="Time"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm" />
                  </div>
                </form>
                <p>{strings.requestActivities.obligatory}</p>
              </div>
            </div>
          </div>
        </Fragment>
      </div>
    )
  }

  const [percentage, setPercentage] = useState<number>(100);
  const [response, error, getEstimations] = ActivitiesService.useGetEstimationsAPI();

  useEffect(() => {
    if (requestData.office && requestData.skill && currentUser) {
      const skillId = requestData.skill.id;
      const officeId = requestData.office.id;

      if (typeof officeId === "number") {
        getEstimations(skillId, officeId);
      }
    }
  }, [requestData.skill, requestData.office]);

  useEffect(() => {
    if (response) {
      const fetchedPercentage = Math.round(response.percentage || 0);
      setPercentage(fetchedPercentage);
    }
  }, [response]);

  const renderStep5 = () => {
    return (
      <div className={"skills-step-container"}>
        {renderStep4()}
        <div className={"text-container"}>
          <h3>
            <span>{strings.requestActivities.step4.title}</span>
            {strings.requestActivities.step4.title2}
          </h3>
          <div className={"subtitle-container"}>
            <p>{strings.requestActivities.step4.subtitle}</p>
            <span>{strings.requestActivities.step4.subtitle2}</span>
            <p>{strings.requestActivities.step4.subtitle3}</p>
            <span>{strings.requestActivities.step4.subtitle4}</span>
            <p>{strings.requestActivities.step4.subtitle5}</p>
            <span>{strings.requestActivities.step4.subtitle6}</span>
            <p>{strings.requestActivities.step4.subtitle7}</p>
          </div>

          <RequestField
            type={RequestFieldType.skill}
            value={'Generica'}
            onSelectedObject={(object) => {
              dispatch(updateRequestData(FormFields.skill, { id: 12, name: 'Generica' }));
            }}
          />

          {requestData.skill && (
            <div className={"input-help-text-container"}>
              <div className={"percentage-banner"}>
                <p>{strings.requestActivities.step4.helpText.title}</p>
                <span
                  className={
                    percentage >= 50 ? "percentage-high" : "percentage-low"
                  }
                >
                  {percentage}%
                </span>
              </div>
              <p className={"help-text"}>
                {strings.requestActivities.step4.helpText.subtitle}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  let renderStep6 = () => {
    return (
      <div className={'request__step__container'}>
        <h3><span>{strings.requestActivities.step5.title}</span>{strings.requestActivities.step5.title2}</h3>
        <LightSignUp registerError={registerError} />
        <p>{strings.requestActivities.step6.sendContent}</p>
      </div>
    )
  }

  let renderSteps = () => {
    switch (step) {
      case 1: return renderStep1()
      case 2: return renderStep2()
      case 3: return renderStep3()
      case 4: return renderStep4()
      case 5: return renderStep5()
      case 6: return renderStep6()
    }
  }

  let validateStep = (step: number): any => {
    switch (step) {
      case 1: return requestData.type !== undefined
      case 2: return validateStep(1) && requestData.activity !== undefined
      case 3: return validateStep(2) && requestData.office !== undefined
      case 4: return validateStep(3) && requestData.datetime !== undefined
      case 5: return validateStep(4) && requestData.skill !== undefined
      case 6: return validateStep(5) && requestResponse !== undefined
    }
  }

  let renderProgress = () => {
    return <progress value={step / RequestActivitySteps.Sixth} />
  }

  let onNext = async () => {
    if (step === RequestActivitySteps.Fifth) {
      requestRequest(requestData)
      await requestResponse
      setStep(step + 1)
    }
    else {
      setStep(step + 1)
    }
  }

  let onSave = async () => {
    if (step === RequestActivitySteps.Sixth) {
      setOpened(true)
    }
  }

  // Use this function for handle guest users. 
  // If guests, show the new request results and the light register form.
  // Otherwise, continue with standard flow.

  let checkUserForConfirm = () => {
    if (!opened) return null
    return <ConfirmModal content={openedType} />
  }

  return (
    <div className={'requestActivities__container'}>
      <h2>{strings.requestActivities.title}</h2>
      {renderProgress()}
      <div className={'requestActivities'}>
        {renderSteps()}
        {step === RequestActivitySteps.Sixth ?
          <div className={'button__container guest_'}>
            <button
              onClick={() => setStep(4)}
              style={{ width: !isMobile ? '112px' : '100%' }}
              className={`button-secondary`}
            >
              Indietro
            </button>
            <button
              style={{ width: !isMobile ? '188px' : '100%' }}
              className={`button-primary ${!tempUser ? 'disabled' : ''}`}
              disabled={tempUser!.first_name === undefined || tempUser!.last_name === undefined || tempUser!.email === undefined || tempUser!.phone === undefined || tempUser!.privacy === false || tempUser!.terms === false ||
                tempUser!.profile!.type === undefined || tempUser!.profile!.lawfirm!.city === undefined
              }
              onClick={onSave}>{isLoading && !registerError ? <span className={'button-loading'} /> : strings.requestActivities.send}</button>
          </div>
          :
          <div className={'button__container'}>
            <button
              style={{ width: !isMobile ? '112px' : '100%' }}
              className={`button-primary ${!validateStep(step) ? 'disabled' : ''}`}
              disabled={!validateStep(step)}
              onClick={onNext}>{isLoading ? <span className={'button-loading'} /> : strings.requestActivities.cta}</button>
          </div>
        }
      </div>

      {checkUserForConfirm()}
      {confirmed && <ConfirmModal content={confirmedType} />}
    </div>
  );
};

export default RequestActivitiesGuest;
